import {
  esimDirectoriesGetLinks,
  esimDirectoriesTypes,
  esimDirectoriesTitles
} from "./../types/esimDirectories.types";
export const esimDirectoriesTabs = () => {
  const data = Object.keys(esimDirectoriesTypes);
  console.log(data);
  return data.map((key, idx) => {
    return {
      id: idx,
      title:
        esimDirectoriesTitles[
          key as typeof esimDirectoriesTypes[keyof typeof esimDirectoriesTypes]
        ],
      value:
        esimDirectoriesGetLinks[
          key as typeof esimDirectoriesTypes[keyof typeof esimDirectoriesTypes]
        ],
      type:
        esimDirectoriesTypes[
          key as typeof esimDirectoriesTypes[keyof typeof esimDirectoriesTypes]
        ]
    };
  });
};
